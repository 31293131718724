import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { useStore } from "vuex";
export default {
  __name: 'App',
  setup: function setup(__props) {
    var getModel = function getModel() {
      var Ismodel = localStorage.getItem('isModel') ? localStorage.getItem('isModel') : 'gpt-3.5-turbo';
      // console.log(Ismodel,'Ismodel')
      if (Ismodel === null || Ismodel === 'gpt-3.5-turbo' || Ismodel === "gpt-3.5-turbo-16k" || Ismodel === 'gpt-4' || Ismodel === 'gpt-4o') {
        return '/ai_api_1/';
      } else if (Ismodel === 'chatglm2-6b') {
        return '/ai_api_2/';
      } else if (Ismodel === "chatglm3-6b") {
        return "/ai_api_3/";
      } else if (Ismodel === "chatglm3-6b-32k") {
        return "/ai_api_4/";
      } else if (Ismodel === "glm-4-9b-chat") {
        return "/ai_api_5/";
      } else if (Ismodel === "glm-4-9b-chat-1m") {
        return "/ai_api_6/";
      } else if (Ismodel === "glm-4v-9b") {
        return "/ai_api_7/";
      }
    };
    var store = useStore();
    var api_final = getModel();
    store.commit('setapi', api_final);
    // debugger
    var sig = new URLSearchParams(window.location.search).get('sig');
    var user_app_id = new URLSearchParams(window.location.search).get('user_app_id');
    var curr = new URLSearchParams(window.location.search).get('curr') || "";
    if (sig && user_app_id) {
      store.commit('REMOVE_TOKEN');
      store.commit("setappid_local", user_app_id);
      store.commit("setis_c", 1);
      store.commit("setsig", sig);
      store.commit("setcurr", curr);
    }
    return function (_ctx, _cache) {
      var _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_component_router_view);
    };
  }
};